import React, { Fragment } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"


// Components
import SEO from "../components/Seo"
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"


/**
 * InformationSecurityPolicy Component
 *
 * @param location {any} -
 * @return React Element {*}
 */
interface IInformationSecurityPolicyDefaultProps {
    location: any,
}

const InformationSecurityPolicyPage = (props: IInformationSecurityPolicyDefaultProps) => {
    const query = useStaticQuery(graphql`
        query {
            informationSecurityPolicy_pageHeader_backgroundImage: file(relativePath: {eq: "policy_pageHeader_backgroundImage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
        }
    `)
    const pageId = "informationSecurityPolicy"
    const pageTitle = "情報セキュリティ"
    const location = props.location
    const { crumbs } = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })

    return (
        <LayoutBase
            pageId={pageId}
            crumbs={crumbs}
            location={location}
        >
            <PageHeader
                title={pageTitle}
                backgroundImage={query.informationSecurityPolicy_pageHeader_backgroundImage.childImageSharp.fluid}
                indicators={true}
            />
            <div className="common-content-layout common-content-format">
                <HandlingInformationSecurityPolicy className="common-content-layout__section"/>
            </div>
        </LayoutBase>
    )
}


/**
 * 情報セキュリティ基本方針
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
export interface IHandlingInformationSecurityPolicyDefaultProps {
    className?: string,
    style?: React.CSSProperties,
}

export const HandlingInformationSecurityPolicy = (props: IHandlingInformationSecurityPolicyDefaultProps) => {
    let rootContainerClassName = `grid-container`
    if (props.className) rootContainerClassName = `${rootContainerClassName} ${props.className}`

    return (
        <div className={rootContainerClassName} style={props.style}>
            <div id="basicPolicyInformationSecurity" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h1>情報セキュリティ基本方針</h1>
                    <p>トランスブート株式会社（以下、当社）は、当社の情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。 </p>
                    <h2 className="utility-margin-medium-top">1. 経営者の責任</h2>
                    <p>当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。</p>
                    <h2 className="utility-margin-medium-top">2. 社内体制の整備</h2>
                    <p>当社は、情報セキュリティの維持及び改善のために担当者を設置し、情報セキュリティ対策を社内の正式な規則として定めます。</p>
                    <h2 className="utility-margin-medium-top">3. 従業員の取組み</h2>
                    <p>当社の従業員は、情報セキュリティのために必要とされる知識、技術を習得し、情報セキュリティへの取り組みを確かなものにします。</p>
                    <h2 className="utility-margin-medium-top">4.法令及び契約上の要求事項の遵守</h2>
                    <p>当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。</p>
                    <h2 className="utility-margin-medium-top">5.違反及び事故への対応</h2>
                    <p>当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。</p>
                </div>
                <div className="cell text-right" style={{marginTop:"4rem"}}>
                    <p>制定日　2021年11月5日</p>
                    <p>トランスブート株式会社<br/>代表取締役　田中　允也</p>
                </div>
            </div>
        </div>
    )
}


export default InformationSecurityPolicyPage
